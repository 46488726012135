import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { MDXLayout as PageLayout } from "../components/blocks/mdx-layout";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Legal Notice" noIndex mdxType="SEO" />

    <h1 {...{
      "id": "legal-notice"
    }}>{`Legal Notice`}</h1>
    <h2 {...{
      "id": "angaben-gemäß--5-tmg"
    }}>{`Angaben gemäß § 5 TMG:`}</h2>
    <p>{`Aman Kalra `}<br />{`
Dr. Grosz Str. 3 `}<br />{`
39126 Magdeburg `}<br /></p>
    <h2 {...{
      "id": "kontakt"
    }}>{`Kontakt`}</h2>
    <p>{`E-mail: `}<a parentName="p" {...{
        "href": "mailto:hi@amankalra.com"
      }}>{`hi@amankalra.com`}</a></p>
    <h2 {...{
      "id": "verantwortlich-für-den-inhalt-nach--55-abs-2-rstv"
    }}>{`Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:`}</h2>
    <p>{`Aman Kalra `}<br />{`
Dr. Grosz Str. 3 `}<br />{`
39126 Magdeburg `}<br /></p>
    <h2 {...{
      "id": "haftung-für-inhalte"
    }}>{`Haftung für Inhalte`}</h2>
    <p>{`Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.`}</p>
    <br />
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
entfernen.
    <h2 {...{
      "id": "haftung-für-links"
    }}>{`Haftung für Links`}</h2>
    <p>{`Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.`}</p>
    <br />
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
wir derartige Inhalte umgehend entfernen.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      